import React from 'react';
import ColorBar from '../color-bar/colorBar';
import DualRangeSlider from '../dual-range-slider/dualRangeSlider';
import Button from '../button/button';
import Dropdown from '../dropdown';
import { colormaps, layerType } from '../../utils/colormaps';
import { classArray, normalize } from '../../utils/helpers';

import './colorBarFilter.scss';

const ColorBarFilter = ({
  id = 'colorBarGraphic',
  className = '',
  colors = colormaps.jet,
  height = 250,
  ticks = [],
  nshades = ticks.length,
  nodataLabel = 'No Data',
  label = '',
  minRange = -Infinity,
  maxRange = Infinity,
  overrideMinRange,
  overrideMaxRange,
  statistics,
  defaultConfig = {},
  type = '',
  onMinChange = () => {},
  onMaxChange = () => {},
  onApplyConfigOverride = () => {},
}) => {
  const dropdownRef = React.useRef();

  const handleDropdownOpen = () => {
    if (dropdownRef && dropdownRef.current) {
      dropdownRef.current.toggleDropdown();
    }
  };

  // should these values move to global store?
  const [filterMinVal, setFilterMinVal] = React.useState(
    overrideMinRange ?? minRange,
  );
  const [filterMaxVal, setFilterMaxVal] = React.useState(
    overrideMaxRange ?? maxRange,
  );

  // this
  const [localMinVal, setLocalMinVal] = React.useState(
    overrideMinRange ?? minRange,
  );
  const [localMaxVal, setLocalMaxVal] = React.useState(
    overrideMaxRange ?? maxRange,
  );

  const cls = classArray(['color-bar-filter-container', className]);

  const resetSlidersHandler = React.useCallback(() => {
    setFilterMinVal(overrideMinRange ?? minRange);
    setFilterMaxVal(overrideMaxRange ?? maxRange);
  }, [
    setFilterMinVal,
    setFilterMaxVal,
    minRange,
    maxRange,
    overrideMinRange,
    overrideMaxRange,
  ]);

  const applyMinMaxValue = React.useCallback(() => {
    onApplyConfigOverride({
      minRange: localMinVal,
      maxRange: localMaxVal,
    });
  }, [localMinVal, localMaxVal, onApplyConfigOverride]);

  React.useEffect(() => {
    if (colors) {
      resetSlidersHandler();
    }
  }, [colors, resetSlidersHandler]);

  React.useEffect(() => {
    onMinChange(filterMinVal);
    onMaxChange(filterMaxVal);
  }, [filterMinVal, filterMaxVal]);

  React.useEffect(() => {
    setLocalMinVal(overrideMinRange ?? minRange);
    setLocalMaxVal(overrideMaxRange ?? maxRange);
  }, [overrideMinRange, overrideMaxRange, minRange, maxRange]);

  return type !== layerType.Categorical ? (
    <div className={cls} style={{ fontSize: '11px' }}>
      <span
        style={{
          fontWeight: 'bold',
        }}
      >
        Filter:{' '}
        <span style={{ fontFamily: 'monospace' }}>
          {`${Math.min(
            100,
            Math.floor(
              normalize(
                filterMinVal,
                overrideMinRange ?? minRange,
                overrideMaxRange ?? maxRange,
              ) * 100,
            ),
          )}-${Math.max(
            0,
            Math.ceil(
              normalize(
                filterMaxVal,
                overrideMinRange ?? minRange,
                overrideMaxRange ?? maxRange,
              ) * 100,
            ),
          )}`}
          %
        </span>
      </span>
      <div className='pl-2 pt-2'>
        {
          <DualRangeSlider
            minRange={overrideMinRange ?? minRange}
            maxRange={overrideMaxRange ?? maxRange}
            minSliderValue={filterMinVal}
            maxSliderValue={filterMaxVal}
            onMinSliderChange={setFilterMinVal}
            onMaxSliderChange={setFilterMaxVal}
          />
        }
        <ColorBar
          id={id}
          colors={colors}
          height={height}
          nshades={nshades}
          nodataLabel={nodataLabel}
          label={label}
          ticks={ticks.length !== 0 ? ticks : defaultConfig.ticks}
        />
      </div>
      <div className='raster-value-updater d-flex flex-row input-group-sm'>
        <div className='mr-1'>
          <input
            id='raster-min-input'
            value={localMinVal}
            onChange={e => setLocalMinVal(Number(e.target.value))}
            type='number'
            className='h-50 mb-1'
          />
          <label htmlFor='raster-min-input'>Min</label>
        </div>
        <div>
          <input
            id='raster-max-input'
            value={localMaxVal}
            onChange={e => setLocalMaxVal(Number(e.target.value))}
            type='number'
            className='h-50 mb-1'
          />
          <label htmlFor='raster-max-input'>Max</label>
        </div>
      </div>
      <br />
      <Dropdown.Menu
        className='btn-width mt-1 mr-2 pr-1'
        handleClick={handleDropdownOpen}
        buttonContent={<small>Compute Range</small>}
        dropdownClass='dropdown dropup btn-block'
        buttonClass='btn-primary mb-2 btn-sm btn-block'
      >
        <div className='pl-2 text-secondary range-dropdown'>
          <small>Select Range</small>
        </div>
        <div className='dropdown-divider' />
        <Dropdown.Item
          className='range-dropdown'
          onClick={() => {
            setLocalMinVal(statistics.stats_min);
            setLocalMaxVal(statistics.stats_max);
          }}
        >
          Min/Max
        </Dropdown.Item>
        <Dropdown.Item
          className='range-dropdown'
          onClick={() => {
            setLocalMinVal(statistics.stats_mean - statistics.stats_std);
            setLocalMaxVal(statistics.stats_mean + statistics.stats_std);
          }}
        >
          2 sigma
        </Dropdown.Item>
        <Dropdown.Item
          className='range-dropdown'
          onClick={() => {
            setLocalMinVal(defaultConfig.minRange);
            setLocalMaxVal(defaultConfig.maxRange);
          }}
        >
          Default
        </Dropdown.Item>
      </Dropdown.Menu>
      <div className='raster-value-updater d-flex flex-row input-group-sm'>
        <Button
          text={<small>Apply</small>}
          title='Apply'
          handleClick={applyMinMaxValue}
          size='small'
          variant='success'
          className='mb-2 mr-1 py-0'
          style={{ boxShadow: 'none' }}
        />
        <Button
          text={<small>Cancel</small>}
          title='Cancel'
          handleClick={() => {
            setLocalMinVal(overrideMinRange ?? minRange);
            setLocalMaxVal(overrideMaxRange ?? maxRange);
          }}
          size='small'
          variant='secondary'
          className='mb-2 mr-2 py-0'
          style={{ boxShadow: 'none' }}
        />
      </div>
    </div>
  ) : null;
};

export default ColorBarFilter;
