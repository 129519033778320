import React, { useEffect, useState } from 'react';

import Button from '../../../app-components/button';
import Card from '../../../app-components/card/card';
import dayjs from 'dayjs';
import SelectProductDropdown from './components/selectProductDropdown';

import {
  useGetProductSelectionQuery,
  useGetProductFilesQuery,
  useGetProductsQuery,
  useGetDatePickerDateQuery,
} from '../../../stores/server-state/products';
import { store } from '../../../stores/app-state/store';

import 'react-tooltip/dist/react-tooltip.css';

import { fromBlob } from 'geotiff';
import { toast } from 'react-toastify';

// TODO: Get slugs by product tag (i.e. 'Watershed Statistics' | 'Snow Analog')
const timeseriesProducts = ['swe-nd'];
const snowAnalogProducts = [
  'globsnow-prob',
  'dem-northern',
  'dem-northern-absolute',
];

const getProductFileByDate = (files = [], date = '', resolution = '') => {
  const dateNoMsISO = date
    ? new Date(date).toISOString().split('.')[0] + 'Z'
    : undefined;
  return files?.find(
    file =>
      file?.datetime === dateNoMsISO && file.spatial_resolution === resolution,
  );
};

const SelectProductCard = ({ defaultProductSlug, disabled = false }) => {
  const [showDateSwitcher, setShowDateSwitcher] = useState(false);
  const [datePickerStartDate, setDatePickerStartDate] = useState();
  const [datePickerEndDate, setDatePickerEndDate] = useState();
  const { productId, doUpdateProductId } = store.useProductId();
  const { productFileId } = store.useProductFileId();
  const { doUpdateProductFileId } = store.useProductFileId();
  const { analogProductId } = store.useAnalogProductId();
  const { analogProductFileId } = store.useAnalogProductFileId();
  const { sourceProductId, doUpdateSourceProductId } =
    store.useSourceProductId();
  const { sourceProductFileId, doUpdateSourceProductFileId } =
    store.useSourceProductFileId();
  const { mySnowAnalogId, doUpdateMySnowAnalogId } = store.useMySnowAnalogId();
  const { spatialResolution, doUpdateSpatialResolution } =
    store.useSpatialResolution();
  const { showSource, doUpdateShowSource } = store.useShowSource();
  const { dateSelection, doUpdateDateSelection } = store.useDateSelection();
  const { doUpdateMyCoordinateId } = store.useMyCoordinateId();

  const { data: products, isLoading: productsLoading } = useGetProductsQuery();
  const { data: productSelection } = useGetProductSelectionQuery({
    productId,
  });
  const { data: productFiles } = useGetProductFilesQuery({
    productId,
  });
  const { data: datePickerDates } = useGetDatePickerDateQuery({
    productId,
  });

  const showAggregate = productSelection?.slug === 'aggregate-analog';
  const resolutionOptions = productSelection?.resolution_options ?? [];

  const isSnowAnalogTool = window.location.href.includes('snow-analog');
  const isWatershedStatisticsTool = window.location.href.includes(
    'watershed-statistics',
  );

  const spatialResolutionClickHandler = e => {
    if (resolutionOptions.length > 1 && spatialResolution !== e.target.value) {
      doUpdateSpatialResolution(e.target.value);
    }
  };

  const validTiff = async blob => {
    const tiff = await fromBlob(blob);
    const arr = await tiff.readRasters();
    const data = arr[0];
    const isAllZeros = data.every(value => value === 0);

    return isAllZeros;
  };

  const downloadRasterClickHandler = async () => {
    try {
      const res = await toast.promise(
        fetch(
          `https://staging.nsidc.org/api/mapservices/CETB_SWE/wcs?service=WCS&request=GetCoverage&version=2.0.1&format=image/tiff&compression=LZW&coverageid=nsidc1_daily_swe_last_in_max_n25km&cql_filter=timestamp=${dateSelection}&crs=EPSG:6901`,
          { signal: AbortSignal.timeout(15000) },
        ),
        {
          pending: 'Downloading Raster',
          error:
            "Error downloading the selected date's raster. WCS may be having issues, please try again later",
        },
      );
      const blob = await res.blob();
      if (validTiff(blob)) {
        toast.error(
          "Error downloading the selected date's raster. WCS may be having issues, please try again later",
        );
        return;
      }

      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');

      const spatialResMap = {
        '25000.0': '25',
        '3125.0': '3_125',
      };

      const fileName = `nsidc1_daily_swe_n${spatialResMap[spatialResolution]}km_${dateSelection}.tif`;
      link.href = url;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch {
      toast.error("Error downloading the selected date's raster", {
        toastId: 1,
      });
    }
  };

  const dateChangeHandler = e => {
    const date = e.target.value;
    if (dayjs(date, 'YYYY-MM-DD', true).isValid() && date !== dateSelection) {
      doUpdateDateSelection(date);
    }
  };

  useEffect(() => {
    if (productId && productFileId && !sourceProductId) {
      doUpdateSourceProductId(productId);
      doUpdateSourceProductFileId(productFileId);
    }
  }, [
    productId,
    productFileId,
    sourceProductId,
    doUpdateSourceProductId,
    doUpdateSourceProductFileId,
  ]);

  const showSourceToggleHandler = showSource => {
    if (showSource) {
      doUpdateProductId(sourceProductId);
      doUpdateProductFileId(sourceProductFileId);
    } else {
      doUpdateProductId(analogProductId);
      doUpdateProductFileId(analogProductFileId);
    }
    doUpdateShowSource(showSource);
  };

  useEffect(() => {
    if (!productId) {
      const selection = products?.find(
        product => product.slug === defaultProductSlug,
      );
      if (selection?.id) {
        doUpdateProductId(selection.id);
      }
    }
  }, [products, doUpdateProductId, defaultProductSlug, productId]);

  useEffect(() => {
    if (products?.length) {
      const selection = products?.find(
        product => product.id === productSelection?.id,
      );
      if (selection) {
        setShowDateSwitcher(timeseriesProducts.includes(selection.slug));
      }
    }
  }, [productSelection, products]);

  useEffect(() => {
    if (
      resolutionOptions?.length &&
      (!spatialResolution || !resolutionOptions.includes(spatialResolution))
    ) {
      doUpdateSpatialResolution(resolutionOptions[0]);
    }
  }, [resolutionOptions, spatialResolution, doUpdateSpatialResolution]);

  useEffect(() => {
    if (productSelection && productFiles) {
      let selection;
      if (isSnowAnalogTool) {
        selection = productFiles.find(
          file =>
            file.product_id === productId &&
            snowAnalogProducts.includes(file.product_slug),
        );
        if (selection) {
          doUpdateProductFileId(selection.id);
          doUpdateSourceProductId(selection.product_id);
          doUpdateSourceProductFileId(selection.id);
        }
      } else if (spatialResolution) {
        if (timeseriesProducts.includes(productSelection?.slug)) {
          selection = getProductFileByDate(
            productFiles,
            dateSelection,
            spatialResolution,
          );
        } else {
          selection = productFiles.find(
            file => file.spatial_resolution === spatialResolution,
          );
        }
        selection
          ? doUpdateProductFileId(selection.id)
          : doUpdateProductFileId('');
      }
    }
  }, [
    dateSelection,
    spatialResolution,
    productFiles,
    productSelection,
    doUpdateProductFileId,
  ]);

  //updated useEffect to default dateSelection to latest available WCS date
  useEffect(() => {
    setDatePickerStartDate(datePickerDates?.min_date.split('T')[0]);
    setDatePickerEndDate(datePickerDates?.max_date.split('T')[0]);

    if (datePickerDates?.max_date.split('T')[0]) {
      doUpdateDateSelection(datePickerDates?.max_date.split('T')[0]);
    }
  }, [datePickerDates]);

  return (
    <Card
      header='Select Product'
      tooltip={`
        <span>
          <strong>Select Date</strong>: Change the date to visualize the
          associated product on the map
          <br />
          <strong>Spatial Resolution</strong>: Toggling the spatial resolution
          will affect the visualization and statistics
        </span>
      `}
    >
      <div className='form-row'>
        <div className='col-4'>
          {!productsLoading ? (
            <SelectProductDropdown
              label='Product Type'
              text={!showAggregate ? productSelection?.description : '--'}
              value={!showAggregate ? productId : ''}
              handleClick={value => {
                value && doUpdateProductId(value);
                if (isSnowAnalogTool) {
                  doUpdateShowSource(true);
                  doUpdateMyCoordinateId('');
                  doUpdateMySnowAnalogId('');
                }
              }}
              options={products
                ?.filter(product =>
                  (isSnowAnalogTool
                    ? snowAnalogProducts
                    : timeseriesProducts
                  )?.includes(product.slug),
                )
                .map(product => ({
                  value: product.id,
                  text: product.description,
                }))}
              buttonClasses='border form-control custom-select text-left w-100'
              labelClassName='pb-1'
            />
          ) : null}
        </div>
        {
          // TODO: get min and max dates from available productilfes
          !!dateSelection?.length &&
            showDateSwitcher &&
            datePickerStartDate &&
            datePickerEndDate && (
              <div className='col-3 ml-2'>
                <label htmlFor='selectDate'>
                  <small>Select Date</small>
                </label>
                <input
                  id='selectDate'
                  className='form-control mt-1'
                  type='date'
                  defaultValue={dateSelection}
                  onChange={dateChangeHandler}
                  min='2015-09-01'
                  max={datePickerEndDate}
                />
              </div>
            )
        }
        {isWatershedStatisticsTool && resolutionOptions.length && !disabled ? (
          <div className='col-3 ml-2'>
            <label htmlFor='spatialResolution'>
              <small>Spatial Resolution</small>
            </label>
            <br />
            <div
              className='btn-group mt-1'
              data-toggle='button'
              id='spatialResolution'
            >
              {resolutionOptions.map(resolution => (
                <Button
                  key={resolution}
                  value={resolution}
                  text={
                    resolution === '6250.0'
                      ? `3.125km`
                      : `${Math.round(parseFloat(resolution)) / 1000}km`
                  }
                  isActive={spatialResolution === resolution}
                  handleClick={spatialResolutionClickHandler}
                  variant='primary'
                  isOutline
                />
              ))}
            </div>
          </div>
        ) : null}
        {isWatershedStatisticsTool && !disabled ? (
          <div className='col-3 mt-2'>
            <label htmlFor='downloadRaster'>
              <small>Download Raster</small>
            </label>
            <br />
            <div
              className='btn-group mt-1'
              data-toggle='button'
              id='downloadRaster'
            >
              <Button
                text='Download Raster'
                handleClick={() => downloadRasterClickHandler()}
                variant='primary'
                isOutline
              />
            </div>
          </div>
        ) : null}
        {isSnowAnalogTool && !disabled ? (
          <div className='col-3 ml-2'>
            <label htmlFor='sourceSwitcher'>
              <small>Snow Analog Toggle</small>
            </label>
            <br />
            <div
              className='btn-group mt-1'
              data-toggle='button'
              id='sourceSwitcher'
            >
              <Button
                value='Source'
                text='Source'
                isActive={showSource}
                handleClick={() => showSourceToggleHandler(true)}
                variant='primary'
                isOutline
                isDisabled={showAggregate}
              />
              <Button
                value='Analog'
                text='Analog'
                title={!mySnowAnalogId ? 'Layer must be selected' : 'Analog'}
                isActive={!showSource}
                handleClick={() => showSourceToggleHandler(false)}
                variant='primary'
                isOutline
                isDisabled={!mySnowAnalogId}
              />
            </div>
          </div>
        ) : null}
      </div>
    </Card>
  );
};

export default SelectProductCard;
